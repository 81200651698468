import React, { useState, useEffect } from 'react';
import { Button, Collapse, Dialog } from 'antd-mobile';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import VerificationPage from './VerificationPage';
import { useAuth } from '../context/AuthContext';
import getConfig from '../config';

const { apiBaseUrl } = getConfig();

const LoginPage = () => {
  const [mobile, setMobile] = useState('');
  const [showVerification, setShowVerification] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const { login } = useAuth();
  const [typedText, setTypedText] = useState('');
  const fullText = "ExpatHelp is an AI service that helps you find answers to all questions related to government and life in general in Germany (we will add other countries shortly). Simply ask your question, upload a document or take a picture and upload that - and ExpatHelp will get you the right answer.";

  useEffect(() => {
    let index = 0;
    const typingInterval = setInterval(() => {
      if (index < fullText.length - 1) {
        setTypedText((prev) => prev + fullText[index]);
        index++;
      } else {
        clearInterval(typingInterval);
      }
    }, 50); // Adjust typing speed here

    return () => clearInterval(typingInterval);
  }, []);

  const handleLogin = async () => {
    const formattedPhoneNumber = `+${mobile}`; // Add + sign before the phone number

    try {
      const response = await fetch(`${apiBaseUrl}/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone_number: formattedPhoneNumber }),
      });

      if (response.ok) {
        setShowVerification(true);
      } else {
        alert('Failed to send verification code');
      }
    } catch (error) {
      console.error('Error sending verification code:', error);
    }
  };

  const handleGoBack = () => {
    setShowVerification(false);
  };

  const handleShowTerms = () => {
    setShowTerms(true);
  };

  const handleCloseTerms = () => {
    setShowTerms(false);
  };

  if (showVerification) {
    return <VerificationPage phone_number={`+${mobile}`} goBack={handleGoBack} />;
  }

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        {/* Left Side: What is ExpatHelp */}
        <div style={{ flex: 1, marginRight: '20px' }}>
          <h1>What is ExpatHelp?</h1>
          <p style={{ fontSize: '16px', lineHeight: '1.6', whiteSpace: 'pre-wrap' }}>
            {typedText}<span className="blinking-cursor">|</span>
          </p>
        </div>

        {/* Right Side: Login Box */}
        <div style={{
          flex: 1, // Ensure equal width
          backgroundColor: '#f5f5f5', // Light background color
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Light shadow for depth
        }}>
          <h2>Get started ...</h2>
          <PhoneInput
            country={'de'} // Set Germany as the default country
            value={mobile}
            onChange={(phone) => setMobile(phone)}
            inputStyle={{ width: '100%' }}
          />
          <Button block color="primary" onClick={handleLogin} style={{ marginTop: '20px' }}>
            Login
          </Button>
          
          <div style={{ marginTop: '20px', fontSize: '12px', textAlign: 'center' }}>
            <p>
              By proceeding, you agree with our{' '}
              <span
                onClick={() => window.open('/terms', '_blank')}
                style={{ color: '#007bff', textDecoration: 'underline', cursor: 'pointer' }}
              >
                terms and conditions
              </span>.
            </p>
          </div>
        </div>
      </div>

      {/* Testimonials Section */}
      <div style={{ marginTop: '60px', marginBottom: '60px', textAlign: 'center' }}>
        <h3>What Our Users Are Saying</h3>
        <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '40px' }}>
          <div style={{
            flex: 1,
            margin: '0 10px',
            padding: '20px',
            backgroundColor: '#f5f5f5',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}>
            <p><strong>Ali, Entrepreneur</strong></p>
            <p>"Moved to Germany in 2008 and one of the biggest issues was always letters, reminders, and so on. Especially as an entrepreneur, the amount of letters one gets is insane. ExpatHelp would have been a life-changer had it been there in 2008."</p>
          </div>
          <div style={{
            flex: 1,
            margin: '0 10px',
            padding: '20px',
            backgroundColor: '#f5f5f5',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}>
            <p><strong>Sarosh, Product Manager</strong></p>
            <p>"I hated the letters and communication to the point that I ignored every letter I got. It was the main reason that almost compelled me to leave Germany for an English-speaking country."</p>
          </div>
          <div style={{
            flex: 1,
            margin: '0 10px',
            padding: '20px',
            backgroundColor: '#f5f5f5',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}>
            <p><strong>Oscar, Senior Manager</strong></p>
            <p>"Even though I speak German fluently, I still get after friends to make sense of letters and navigating German bureaucracy. ExpatHelp has been a great help. I even got a reply from the Citizenship office based on a letter written by ExpatHelp!"</p>
          </div>
        </div>
      </div>

      {/* FAQ Section - Positioned Below */}
      <div style={{ marginTop: '30px', fontSize: '14px', lineHeight: '1.6' }}>
        <h3>Frequently Asked Questions (FAQs)</h3>
        <Collapse accordion>
          <Collapse.Panel key="1" title="1. Why do you need my phone number?">
            <p>
              We use your phone number for login. You don't need to create a password, 
              and you get an authentication code via SMS. This is more secure and convenient 
              for users, even though it's more expensive for us than email.
            </p>
          </Collapse.Panel>

          <Collapse.Panel key="2" title="2. Do you sell or use my phone number for marketing activities?">
            <p>
              No, we do not sell your data or use your data for any purpose other than 
              your use of ExpatHelp.
            </p>
          </Collapse.Panel>

          <Collapse.Panel key="3" title="3. How do you comply with GDPR and other privacy regulations?">
            <p>
              We are committed to protecting your privacy and complying with GDPR and other 
              privacy regulations. We only collect the data necessary to provide our service, 
              and we do not share your data with third parties without your consent. Our third-party 
              providers also comply with strict data protection regulations.
            </p>
          </Collapse.Panel>

          <Collapse.Panel key="4" title="4. How are you different from ChatGPT and Claude etc?">
            <p>
              ChatGPT and Claude etc are general-purpose chatbots. ExpatHelp is a specialized chatbot 
              for expats in Germany (and beyond). We are focused on providing the best possible answers to questions
              related to government and life in general. Our knowledge isn't only coming from AI but rather from
              the experiences of real people. Furthermore, we have set up the platform primarily to help people navigate 
              challenges and get reliable answers to their queries.
            </p>
          </Collapse.Panel>
        </Collapse>
      </div>

      {/* Terms and Conditions Dialog */}
      <Dialog visible={showTerms} onClose={handleCloseTerms} title="Terms and Conditions">
        <p>
          [Your terms and conditions text here...]
        </p>
        <Button onClick={handleCloseTerms}>Close</Button>
      </Dialog>
    </div>
  );
};

export default LoginPage;
