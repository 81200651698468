import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';  // Import the arrow icons
import { SearchOutline } from 'antd-mobile-icons';
import { RiDeleteBinLine } from 'react-icons/ri';
import axios from 'axios';
import getConfig from '../config';
import MainContent from './MainContent';
import '../styles.css';

const Dashboard = () => {
  const { apiBaseUrl } = getConfig();
  const [collapsed, setCollapsed] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [threads, setThreads] = useState([]);
  const [selectedThread, setSelectedThread] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const userId = localStorage.getItem('user_id');
    if (!userId) {
      navigate('/login');
    } else {
      fetchThreads();
    }

    // Check if the device is an iPhone
    if (/iPhone/.test(navigator.userAgent)) {
      setCollapsed(true);
    }
  }, []);

  const fetchThreads = async () => {
    try {
      const userId = localStorage.getItem('user_id');
      const response = await axios.get(`${apiBaseUrl}/threads/users/${userId}/threads`);
      const formattedThreads = response.data.threads.map((thread) => ({
        ...thread,
        title: extractTitle(thread.title),
      }));
      setThreads(formattedThreads);
    } catch (error) {
      console.error('Error fetching threads:', error);
    }
  };

  const handleThreadClick = (thread) => {
    setSelectedThread(thread);
  };

  const handleNewTopic = () => {
    setSelectedThread(null); // Reset selectedThread to start a new thread
  };

  const extractTitle = (title) => {
    try {
      const parsedTitle = JSON.parse(title);
      return parsedTitle.title || title;
    } catch (e) {
      return title;
    }
  };

  const filteredThreads = threads.filter((thread) =>
    thread.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const handleDeleteThread = async (threadId) => {
    try {
      const userId = localStorage.getItem('user_id');
      await axios.delete(`${apiBaseUrl}/threads/threads/${threadId}`, {
        data: { user_id: userId },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      setThreads((prevThreads) => prevThreads.filter((thread) => thread.thread_id !== threadId));
    } catch (error) {
      console.error('Error deleting thread:', error);
    }
  };

  return (
    <div className="dashboard">
      <div className={`sidebar${collapsed ? '.collapsed' : ''}`}>
        <div className="sidebar-header">
          <button className="toggle-button" onClick={toggleCollapse}>
            {collapsed ? <FaChevronRight style={{ color: '#333' }} /> : <FaChevronLeft style={{ color: '#333' }} />}
          </button>
          {!collapsed && <h2 style={{ color: '#333' }}>Library</h2>}
        </div>
        {!collapsed && (
          <>
            <button className="new-topic-button" onClick={handleNewTopic}>
              New Topic
            </button>
            <div className="search-box">
              <input
                type="text"
                placeholder="Search threads..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <SearchOutline className="search-icon" />
            </div>
            <ul className="topics-list">
              {filteredThreads.map((thread) => (
                <li
                  key={thread.thread_id}
                  className="topic-item"
                  onMouseEnter={(e) => e.currentTarget.classList.add('hovered')}
                  onMouseLeave={(e) => e.currentTarget.classList.remove('hovered')}
                >
                  <span onClick={() => handleThreadClick(thread)}>
                    {thread.title}
                  </span>
                  <RiDeleteBinLine
                    className="delete-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteThread(thread.thread_id);
                    }}
                  />
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
      <div className={`main-content-wrapper ${collapsed ? 'expanded' : ''}`}>
        <MainContent 
          selectedThread={selectedThread} 
          setSelectedThread={setSelectedThread} 
          fetchThreads={fetchThreads}
        />
      </div>
    </div>
  );
};

export default Dashboard;
