// src/pages/VerificationPage.js
import React, { useState } from 'react';
import { Button } from 'antd-mobile';
import { useAuth } from '../context/AuthContext';
import getConfig from '../config';

const { apiBaseUrl } = getConfig();


const VerificationPage = ({ phone_number, goBack }) => {
  const [code, setCode] = useState(Array(6).fill(''));
  const [error, setError] = useState(null);
  const { login } = useAuth();

  const handleInput = (index, event) => {
    const value = event.target.value.slice(0, 1); // Ensure only one character
    const newCode = [...code];
    newCode[index] = value; // Set the digit in the appropriate box
    setCode(newCode);

    // Move focus to the next input field if a digit was entered
    if (value !== '' && index < 5) {
      document.getElementById(`digit-${index + 1}`).focus();
    }
  };

  const handleSubmit = async () => {
    const verificationCode = code.join('');
    if (verificationCode.length !== 6) {
      alert('Please enter a 6-digit verification code you received as sms');
      return;
    }

    try {
      await login(phone_number, verificationCode);
    } catch (error) {
      setError('Invalid verification code. Please try again.');
      console.error('Error verifying code:', error);
    }
  };

  const handleResendCode = async () => {
    try {
      const response = await fetch(`${apiBaseUrl}/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone_number }),
      });

      if (response.ok) {
        alert('Verification code resent. Please check your phone.');
      } else {
        alert('Failed to resend verification code. Please try again later.');
      }
    } catch (error) {
      console.error('Error resending verification code:', error);
    }
  };

  return (
    <div>
      <div style={{ padding: '20px', textAlign: 'center' }}>
        <h2>Enter SMS Verification Code</h2>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', margin: '20px 0' }}>
          {code.map((digit, index) => (
            <input
              key={index}
              id={`digit-${index}`}
              value={digit}
              onChange={(event) => handleInput(index, event)}
              maxLength={1}
              style={{
                width: '40px',
                height: '40px',
                textAlign: 'center',
                border: '2px solid #ccc',
                borderRadius: '4px',
                fontSize: '20px',
              }}
              inputMode="numeric"
              type="text"
            />
          ))}
        </div>
        <Button block color="primary" onClick={handleSubmit}>
          Verify
        </Button>
        <Button block color="default" onClick={handleResendCode} style={{ marginTop: '10px' }}>
          Resend Code
        </Button>
        <Button block color="default" onClick={goBack} style={{ marginTop: '10px' }}>
          Go Back
        </Button>
      </div>
    </div>
  );
};

export default VerificationPage;
