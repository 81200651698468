// src/config.js

const config = {
    development: {
      apiBaseUrl: 'http://localhost:8000',
    },
    production: {
      apiBaseUrl: 'https://expat-help-py-bb8d2f683fbb.herokuapp.com', // Replace with your actual production URL
    },
  };
  
  const getConfig = () => {
    const env = process.env.NODE_ENV || 'development';
    return config[env];
  };
  
  export default getConfig;
  