// src/pages/TermsAndConditions.js
import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <h1>Terms and Conditions</h1>
      <p>
        Welcome to ExpatHelp, a service provided by WorkHub Platform Inc. By accessing or using ExpatHelp, you agree to comply with and be bound by the following terms and conditions.
      </p>
      <h2>Company Information</h2>
      <p>
        ExpatHelp is owned and operated by:
        <br />
        <strong>WorkHub Platform Inc.</strong>
        <br />
        c/o Raie & co,
        <br />
        1875 S. Bascom Ave, Suite 2400,
        <br />
        Campbell, CA 95008, USA
      </p>
      <h2>Services Provided</h2>
      <p>ExpatHelp is designed to assist users by providing personalized responses to their queries through the use of AI models. The platform uses a credits-based system to provide these services, where each credit is valued at USD 1.</p>
      <h2>User Data Collection and Usage</h2>
      <p>We collect the following user data:</p>
      <ul>
        <li>Phone number (for passwordless login)</li>
        <li>Optional profile information: location (city and country), marital status, number of children, employment status, and visa status.</li>
      </ul>
      <h2>Data Sharing</h2>
      <p>We do not sell or share your personal data with third parties. However, we do use the services of third-party providers as described below.</p>
      <h2>Third-Party Services</h2>
      <p>To deliver our services, ExpatHelp uses the following third-party providers:</p>
      <ul>
        <li>OpenAI: <a href="https://openai.com/policies/row-privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
        <li>Anthropic: <a href="https://support.anthropic.com/en/collections/4078534-privacy-legal" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
        <li>Twilio: <a href="https://www.twilio.com/en-us/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
        <li>Heroku: <a href="https://devcenter.heroku.com/articles/security-privacy-compliance" target="_blank" rel="noopener noreferrer">Privacy and Security</a></li>
      </ul>
      <h2>Credits and Payments</h2>
      <p>
        Each user receives 10 credits upon signing up. The number of credits provided at sign-up is subject to change based on promotional activities or other business decisions.
        Users can purchase additional credits at any time. Each credit costs USD 1. Once purchased, credits are non-refundable.
      </p>
      <h2>Account Closure</h2>
      <p>Users can request to close their account or obtain assistance through our online Chat Widget.</p>
      <h2>Compliance with Laws</h2>
      <p>ExpatHelp complies with the legal regulations of the United States of America and the state of California. Additionally, we adhere to local data privacy regulations in the countries where we operate.</p>
      <h2>Modifications</h2>
      <p>We reserve the right to modify these terms and conditions at any time. Any changes will be effective immediately upon posting on our website.</p>
      <h2>Contact Information</h2>
      <p>For any questions, concerns, or support needs, please contact us via our online Chat Widget.</p>

      <h1>Privacy Policy</h1>
      <p>This Privacy Policy outlines how ExpatHelp, owned and operated by WorkHub Platform Inc., collects, uses, and protects your personal data. By using our services, you agree to the practices described in this policy.</p>
      <h2>Data We Collect</h2>
      <p>We collect the following user data:</p>
      <ul>
        <li>Phone Number: Used solely for passwordless login and authentication purposes.</li>
        <li>Profile Information (Optional): Includes location (city and country), marital status, number of children, employment status, and visa status.</li>
      </ul>
      <h2>How We Use Your Data</h2>
      <p>We use your data to personalize our services, improve user experience, and secure user accounts. We do not sell or share your personal data with third parties.</p>
      <h2>Third-Party Services</h2>
      <p>We use the following third-party services:</p>
      <ul>
        <li>OpenAI: <a href="https://openai.com/policies/row-privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
        <li>Anthropic: <a href="https://support.anthropic.com/en/collections/4078534-privacy-legal" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
        <li>Twilio: <a href="https://www.twilio.com/en-us/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
        <li>Heroku: <a href="https://devcenter.heroku.com/articles/security-privacy-compliance" target="_blank" rel="noopener noreferrer">Privacy and Security</a></li>
      </ul>
      <h2>Data Security</h2>
      <p>We implement robust security measures to protect your personal data from unauthorized access, alteration, disclosure, or destruction.</p>
      <h2>Data Retention</h2>
      <p>We retain your data only as long as necessary to fulfill the purposes for which it was collected or as required by law.</p>
      <h2>Your Rights</h2>
      <p>You have the right to access, correct, or delete your personal data at any time. You can request these actions through our online Chat Widget.</p>
      <h2>Compliance with Laws</h2>
      <p>ExpatHelp complies with the data protection laws of the United States and other applicable local regulations.</p>
      <h2>Changes to This Policy</h2>
      <p>We may update this Privacy Policy from time to time. Any changes will be posted on our website and will be effective immediately.</p>
      <h2>Contact Us</h2>
      <p>For any questions or concerns regarding your privacy, please contact us through our online Chat Widget.</p>
    </div>
  );
};

export default TermsAndConditions;
