import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { List, Popup } from 'antd-mobile';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useAuth } from '../context/AuthContext';
import '../styles.css';  // Import the styles.css file

const Header = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  // Fetch the credits from the user's profile data
  const credits = user?.profile?.credits || 0;

  const menuItems = [
    { key: 'get-help', label: 'Get Help', onClick: () => navigate('/get-help') },
    { key: 'your-profile', label: 'Your Profile', onClick: () => navigate('/profile') },
    { key: 'logout', label: 'Logout', onClick: () => logout() },
  ];

  const toggleMenu = () => {
    setVisible(!visible);
  };

  // Function to handle the Top Up button click
  const handleTopUp = () => {
    const user_id = localStorage.getItem('user_id');
    console.log('User ID:', user_id);
    if (user && user_id) {
      const checkoutUrl = `https://buy.stripe.com/00g7ucfYd3cD5YA004?utm_campaign=${user_id}&client_reference_id=${user_id}`;
      window.location.href = checkoutUrl;  // Redirect to Stripe's checkout page
    } else {
      console.error("User ID not found. Cannot proceed to checkout.");
    }
  };

  return (
    <div className="header">
      <div className="header-left">
        {user && (
          <>
            <div onClick={toggleMenu} className="hamburger-menu">
              <span className="hamburger-line"></span>
              <span className="hamburger-line"></span>
              <span className="hamburger-line"></span>
            </div>
            <span className="credits-text" style={{ display: 'flex', alignItems: 'center' }}>
              Credits: {credits}
              <Tooltip title="Every message to ExpatHelp costs 1 credit">
                <InfoCircleOutlined style={{ marginLeft: '12px', color: '#ffffff' }} />
              </Tooltip>
              <button 
                onClick={handleTopUp}
                style={{
                  marginLeft: '12px',
                  padding: '5px 10px',
                  backgroundColor: '#ffffff',
                  borderColor: '#ffffff',
                  color: '#333333',
                  cursor: 'pointer',
                  borderRadius: '4px'
                }}
              >
                Top Up
              </button>
            </span>
          </>
        )}
      </div>
      <h1>
        <Link to="/" className="header-link">
          ExpatHelp
        </Link>
      </h1>

      {user && (
        <Popup
          visible={visible}
          onMaskClick={() => setVisible(false)}
          position="left"
          bodyStyle={{ width: '70%', maxWidth: '300px', backgroundColor: 'white', padding: '10px' }}
        >
          <List>
            {menuItems.map((item) => (
              <List.Item key={item.key} onClick={() => { item.onClick(); setVisible(false); }}>
                {item.label}
              </List.Item>
            ))}
          </List>
        </Popup>
      )}
    </div>
  );
};

export default Header;
