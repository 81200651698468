// src/context/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import getConfig from '../config';

const { apiBaseUrl } = getConfig();

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const navigate = useNavigate();

  useEffect(() => {
    const validateToken = async () => {
      if (token) {
        console.log('Validating token');
        try {
          const response = await fetch(`${apiBaseUrl}/auth/validate_token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ token }),
          });
          const data = await response.json();

          if (data.valid) {
            const userId = localStorage.getItem('user_id'); // Get the userId from localStorage
            const profileResponse = await fetch(`${apiBaseUrl}/user/profile/${userId}`, {  // Update the endpoint
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
              },
            });
            const profileData = await profileResponse.json();
            setUser({ userId, profile: profileData });
          } else {
            throw new Error('Invalid token');
          }
        } catch (error) {
          console.error('Error validating token:', error);
          setToken('');
          localStorage.removeItem('token');
          localStorage.removeItem('user_id');
          navigate('/login');
        }
      }
    };
    validateToken();
  }, [token, navigate]);

  const login = async (phone_number, code) => {
    const response = await fetch(`${apiBaseUrl}/auth/complete_registration`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ phone_number, code }),
    });

    const data = await response.json();
    if (response.ok) {
      const { user } = data;
      console.log('User logged in:', user);
      console.log('Token:', data.token);
      const profileResponse = await fetch(`${apiBaseUrl}/user/profile/${user.userId}`, {  // Update the endpoint
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${data.token}`,
        },
      });
      const profileData = await profileResponse.json();

      setToken(data.token);
      setUser({ ...user, profile: profileData });
      localStorage.setItem('token', data.token);
      localStorage.setItem('user_id', user.userId); // Store user_id locally
      navigate('/');
    } else {
      throw new Error(data.detail);
    }
  };

  const logout = async () => {
    await fetch(`${apiBaseUrl}/auth/logout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ token }),
    });
    setUser(null);
    setToken('');
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    navigate('/login');
  };

  return (
    <AuthContext.Provider value={{ user, token, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
