// src/App.js
import React , { useEffect } from 'react';
import { ConfigProvider } from 'antd-mobile';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import enUS from 'antd-mobile/es/locales/en-US';
import LoginPage from './pages/LoginPage';
import ProfilePage from './pages/ProfilePage';
import Header from './components/Header';
import Footer from './components/Footer';
import Dashboard from './components/Dashboard';
import SuccessPage from './pages/SuccessPage';
import TermsAndConditions from './pages/TermsAndConditions';
import { message } from 'antd';

import { AuthProvider } from './context/AuthContext';
import './styles.css';  // Import your CSS file

message.config({
  top: 80,  // Adjust this value to control the vertical position
  duration: 5,  // Set default duration for messages
  maxCount: 3,  // Limit the number of messages shown at once
  rtl: false,  // Right to Left (set to true if you want RTL support)
});

function App() {

  // Redirect to HTTPS
  useEffect(() => {
    if (window.location.protocol === 'http:' && window.location.hostname !== 'localhost') {
      window.location.href = window.location.href.replace('http:', 'https:');
    }
  }, []);


  return (
    <Router>
      <AuthProvider>
      <ConfigProvider locale={enUS}>
        <div className="app-container">
          <Header />
          <div className="main-content">
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/get-help" element={<Dashboard />} />
              <Route path="/success" element={<SuccessPage />} /> 
              <Route path="/terms" element={<TermsAndConditions />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </ConfigProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
