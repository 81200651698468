import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import getConfig from '../config';
import { message as antdMessage } from 'antd';

const SuccessPage = () => {
  const { apiBaseUrl } = getConfig();
  const location = useLocation();
  const navigate = useNavigate();
  const [message, setMessage] = useState("Processing your payment...");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get('session_id');
    const clientReferenceId = queryParams.get('utm_campaign');

    if (sessionId && clientReferenceId) {
      // Call your backend to confirm the session and update the user's credits
      console.log('Session ID:', sessionId);
      console.log('User ID:', clientReferenceId);
      fetch(`${apiBaseUrl}/user/confirm-payment?session_id=${sessionId}&user_id=${clientReferenceId}`)
        .then(response => response.json())
        .then(data => {
          if (data.status === 'success') {
            antdMessage.success({
                content: `Payment Successful! Credits added: ${data.credits_added}`,
                duration: 5,  // Hide the message after 5 seconds
            }); 
            setMessage(`Payment Successful! Credits added: ${data.credits_added}`);
          } else {
            antdMessage.error({
                content: `Failed to add credits.`,
                duration: 5,  // Hide the message after 5 seconds
            });
            setMessage('Failed to add credits. Please contact support.');
          }
        })
        .catch(error => {
          console.error('Error confirming payment:', error);
          antdMessage.error({
            content: `Failed to add credits.`,
            duration: 5,  // Hide the message after 5 seconds
        });
          setMessage('Error processing payment. Please try again later.');
        });
    } else {
        antdMessage.error({
            content: `Invalid payment information. Please try again..`,
            duration: 5,  // Hide the message after 5 seconds
        });
      setMessage('Invalid payment information. Please try again.');
    }

    // Redirect after a delay
    const timer = setTimeout(() => {
      navigate('/');  // Redirect to the home page
    }, 2000); // 5 seconds delay

    return () => clearTimeout(timer); // Clean up the timer on component unmount
  }, [location, apiBaseUrl, navigate]);

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <h1>{message}</h1>
      <p>You will be redirected to the home page shortly...</p>
    </div>
  );
};

export default SuccessPage;
