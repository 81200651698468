import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { UploadOutline } from 'antd-mobile-icons';
import axios from 'axios';
import { CircularProgress, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Markdown from 'react-markdown';
import getConfig from '../config';
import { message } from 'antd';
import '../styles.css';

const MainContent = ({ selectedThread, setSelectedThread = () => {}, fetchThreads }) => {
  const { apiBaseUrl } = getConfig();
  const [question, setQuestion] = useState('');
  const [conversation, setConversation] = useState([]);
  const [topic, setTopic] = useState('Ask anything or upload the letter you received');
  const [suggestedQuestions, setSuggestedQuestions] = useState([]);
  const [fileUploads, setFileUploads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [credits, setCredits] = useState(0);  // New state for credits
  const { user } = useAuth();

  useEffect(() => {
    if (selectedThread) {
      loadThread(selectedThread);
    } else {
      setConversation([]);
      setTopic('Ask anything or upload the letter you received');
    }
  }, [selectedThread]);

  useEffect(() => {

    fetchUserCredits();  // Fetch user credits when component mounts
    

  }, []); 

  const fetchUserCredits = async () => {
    try {
      //const response = await axios.get(`${apiBaseUrl}/user/${user.userId}/credits`);
      setCredits(user?.profile?.credits);
    } catch (error) {
      console.error('Error fetching credits:', error);
    }
  };

  const loadThread = async (thread) => {
    setTopic(extractTitle(thread.title));
    try {
      const response = await axios.get(`${apiBaseUrl}/threads/users/${user.userId}/threads/${thread.thread_id}`);
      console.log('Thread loaded:', response.data);  // Debugging log
      
      const messages = response.data.messages.slice(1);  // Skip the first message
  
      setConversation(messages.map((message) => ({
        role: message.role === 'user' ? 'You' : 'ExpatAssistant',
        content: message.content,
      })));
  
      fetchSuggestedQuestions(thread.thread_id);
    } catch (error) {
      console.error('Error loading thread:', error);
    }
  };

  const extractTitle = (title) => {
    try {
      const parsedTitle = JSON.parse(title);
      return parsedTitle.title || title;
    } catch (e) {
      return title;
    }
  };

  const handleAskQuestion = async () => {
    const credits = user?.profile?.credits || 0;
    if (credits <= 0) {
      message.error({
        content: "You have no credits left. Please top up your credits to continue.",
        duration: 5,  // Hide the message after 5 seconds
    }); 
      return;
    }

    if (question || fileUploads.length > 0) {
        setLoading(true);
        const formData = new FormData();
        formData.append('user_id', user.userId);
        formData.append('message_content', question);  // Use message_content for both cases

        fileUploads.forEach((file, index) => {
            formData.append(`image${index + 1}`, file);
        });

        try {
            let response;
            if (selectedThread) {
                console.log('Adding follow-up message to existing thread');
                response = await axios.post(`${apiBaseUrl}/threads/threads/${selectedThread.thread_id}/messages`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });

                console.log('Follow-up response:', response.data);

                setConversation((prev) => [
                    ...prev,
                    { role: 'You', content: question },
                    { role: 'ExpatAssistant', content: response.data.ai_response.content }
                ]);
            } else {
                console.log('Creating new thread');
                response = await axios.post(`${apiBaseUrl}/threads/threads`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });

                const newThread = {
                    thread_id: response.data.thread_id,
                    title: response.data.title,
                };

                setSelectedThread(newThread);
                setTopic(extractTitle(response.data.title));
                setConversation([
                    { role: 'You', content: question },
                    { role: 'ExpatAssistant', content: response.data.ai_response.content }
                ]);

                fetchThreads();  // Fetch threads again to update the sidebar
            }

            setQuestion('');
            setFileUploads([]);
            fetchSuggestedQuestions(selectedThread ? selectedThread.thread_id : response.data.thread_id);
            fetchUserCredits();  // Refresh credits after sending a message
        } catch (error) {
            console.error('Error sending message:', error);
        } finally {
            setLoading(false);
        }
    }
};

  const fetchSuggestedQuestions = async (threadId) => {
    if (threadId) {
      try {
        const response = await axios.post(`${apiBaseUrl}/threads/get_question_suggestions`, {
          user_id: user.userId,
          thread_id: threadId,
        });
        setSuggestedQuestions(response.data.suggested_questions);
      } catch (error) {
        console.error('Error fetching suggested questions:', error);
      }
    }
  };

  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    setFileUploads((prevFiles) => [...prevFiles, ...files]);
  };

  const handleRemoveFile = (indexToRemove) => {
    setFileUploads((prevFiles) => prevFiles.filter((_, index) => index !== indexToRemove));
  };

  return (
    <div className="main-content" style={{ paddingTop: '10px' }}>
      {conversation.length === 0 ? (
        <div className="default-view">
          <h2>{topic}</h2>
          <div className="ask-box">
            <textarea
              className="ask-input"
              placeholder="Ask anything e.g How to reply to Police letter? How do I reply to the Inkasso I got?"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              disabled={loading || credits <= 0} // Disable input while loading or if no credits
            />
            <div className="ask-actions">
              <label className="file-upload">
                <UploadOutline className="upload-icon" />
                <input type="file" multiple onChange={handleFileUpload} style={{ display: 'none' }} disabled={loading || credits <= 0} />
              </label>
              <button className="ask-button" onClick={handleAskQuestion} disabled={loading || !question.trim() || credits <= 0}>
                {loading ? <CircularProgress size={24} style={{ color: 'white' }} /> : <span>➔</span>}
              </button>
            </div>
            <div className="uploaded-files">
              {fileUploads.map((file, index) => (
                <div key={index} className="uploaded-file">
                  <span>{file.name}</span>
                  <IconButton size="small" onClick={() => handleRemoveFile(index)} disabled={loading || credits <= 0}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              ))}
            </div>
            {credits <= 0 && <p>You have no credits left. Please top up your credits to continue.</p>} {/* Show message when no credits */}
            {loading && <p>Please wait...</p>} {/* Show "Please wait..." */}
          </div>
        </div>
      ) : (
        <div className="conversation-view">
          <h2>{topic}</h2>
          {conversation.map((conv, index) => (
            <div key={index} className={`conversation-item ${conv.role === 'You' ? 'user-message' : 'assistant-message'}`}>
              <p><strong>{conv.role}:</strong></p>
              <Markdown>{conv.content}</Markdown>
            </div>
          ))}
          <div className="follow-up-box">
            <textarea
              className="ask-input"
              placeholder="Ask follow-up..."
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              disabled={loading || credits <= 0} // Disable input while loading or if no credits
            />
            <div className="ask-actions">
              <label className="file-upload">
                <UploadOutline className="upload-icon" />
                <input type="file" multiple onChange={handleFileUpload} style={{ display: 'none' }} disabled={loading || credits <= 0} />
              </label>
              <button className="ask-button" onClick={handleAskQuestion} disabled={loading || !question.trim() || credits <= 0}>
                {loading ? <CircularProgress size={24} style={{ color: 'white' }} /> : <span>➔</span>}
              </button>
            </div>
            <div className="uploaded-files">
              {fileUploads.map((file, index) => (
                <div key={index} className="uploaded-file">
                  <span>{file.name}</span>
                  <IconButton size="small" onClick={() => handleRemoveFile(index)} disabled={loading || credits <= 0}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              ))}
            </div>
            {credits <= 0 && <p>You have no credits left. Please top up your credits to continue.</p>} {/* Show message when no credits */}
            {loading && <p>Please wait...</p>} {/* Show "Please wait..." */}
          </div>
        </div>
      )}

      <div className="suggested-questions">
        <div className="suggested-questions-container">
          {suggestedQuestions.map((question, index) => (
            <button key={index} onClick={() => setQuestion(question)} disabled={loading || credits <= 0}>
              {question}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MainContent;
