// src/pages/ProfilePage.js
import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Picker, Checkbox, List,Toast } from 'antd-mobile';
import { useAuth } from '../context/AuthContext';
import getConfig from '../config';

const { apiBaseUrl } = getConfig();

// Predefined options
const familyStatusOptions = [
  { label: 'Single', value: 'single' },
  { label: 'Married', value: 'married' },
  { label: 'Divorced', value: 'divorced' },
  { label: 'Widowed', value: 'widowed' },
];

const employmentStatusOptions = [
  { label: 'Full Time', value: 'full_time' },
  { label: 'Contract', value: 'contract' },
  { label: 'Part Time', value: 'part_time' },
  { label: 'No Job', value: 'no_job' },
];

const visaStatusOptions = [
  { label: 'Blue Card', value: 'blue_card' },
  { label: 'Student Visa', value: 'student_visa' },
  { label: 'Work Visa', value: 'work_visa' },
  { label: 'Dependent Visa', value: 'dependent_visa' },
  { label: 'Permanent Residency', value: 'permanent_residency' },
  { label: 'Other', value: 'other' },
];

const citiesInGermany = [
  "Berlin", "Hamburg", "Munich", "Cologne", "Frankfurt", "Stuttgart", "Düsseldorf", "Dortmund", 
  "Essen", "Leipzig", "Bremen", "Dresden", "Hanover", "Nuremberg", "Duisburg", "Bochum",
  "Wuppertal", "Bielefeld", "Bonn", "Mannheim", "Karlsruhe", "Wiesbaden", "Münster", "Augsburg"
  // Add more cities as needed
];

const ProfilePage = () => {
  const { user, token } = useAuth();
  const [profile, setProfile] = useState({
    country: 'Germany',  // Preselect Germany
    city: 'Berlin',  // Default to Berlin
    familyStatus: 'single',  // Default to Single
    employmentStatus: 'full_time',  // Default to Full Time
    numberOfKids: 0,
    isStudent: false,
    visaStatus: 'blue_card',  // Default to Blue Card
  });
  const [citySuggestions, setCitySuggestions] = useState([]);

  useEffect(() => {
    if (user && user.profile) {
      setProfile({
        country: user.profile.country || 'Germany',
        city: user.profile.city || 'Berlin',
        familyStatus: user.profile.family_status || 'single',
        employmentStatus: user.profile.employment_status || 'full_time',
        numberOfKids: user.profile.number_of_children || 0,
        isStudent: user.profile.is_student === 'True' ? true : false,
        visaStatus: user.profile.visa_status || 'blue_card',
        credits: user.profile.credits || 200  // Load the user's credits
      });
    }
  }, [user]);

  const handleCityChange = (value) => {
    setProfile({ ...profile, city: value });
    if (value) {
      const filteredCities = citiesInGermany.filter(city =>
        city.toLowerCase().includes(value.toLowerCase())
      );
      setCitySuggestions(filteredCities);
    } else {
      setCitySuggestions([]);
    }
  };

  const handleCitySelect = (city) => {
    setProfile({ ...profile, city });
    setCitySuggestions([]);
  };

  const handleFormChange = (changedValues) => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      ...changedValues,
    }));
  };
  
  const handleNumberOfKidsChange = (value) => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      numberOfKids: value,  // Directly update the numberOfKids field
    }));
  };
  const handleStudentChange = (checked) => {
    setProfile({
      ...profile,
      isStudent: checked,
      visaStatus: checked ? 'student_visa' : profile.visaStatus,
    });
  };

  const handleSave = async () => {
    try {
      const profileData = {
        user_id: user.userId,
        country: profile.country,
        city: profile.city,
        family_status: profile.familyStatus,
        employment_status: profile.employmentStatus,
        number_of_children: parseInt(profile.numberOfKids, 10),
        visa_status: profile.visaStatus,
        is_student: profile.isStudent
      };

      const response = await fetch(`${apiBaseUrl}/user/profile`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(profileData),
      });

      if (response.ok) {
        const data = await response.json();
        Toast.show({
          icon: 'success',
          content: 'Profile updated successfully!',
        });
        console.log('Profile updated:', data.profile);
      } else {
        const errorData = await response.json();
        Toast.show({
          icon: 'fail',
          content: `Error: ${errorData.detail}`,
        });
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      Toast.show({
        icon: 'fail',
        content: 'Failed to update profile. Please try again later.',
      });
    }
  };
  return (
    <div>
      <div style={{ padding: '20px' }}>
        <h2>Profile</h2>
        <p>Completely anonymous. We use this data to personalize recommendations only.</p>
        <Form
          onFinish={handleSave}
          onValuesChange={(changedValues) => handleFormChange(changedValues)}
          footer={
            <Button block type="submit" color="primary">
              Save
            </Button>
          }
        >
          <Form.Item label="Country">
            <Input
              value={profile.country}
              readOnly
            />
          </Form.Item>
          <Form.Item label="City">
            <Input
              placeholder="Enter your city"
              value={profile.city}
              onChange={(e) => handleCityChange(e.target.value)}
            />
            {citySuggestions.length > 0 && (
              <List>
                {citySuggestions.map((city) => (
                  <List.Item key={city} onClick={() => handleCitySelect(city)}>
                    {city}
                  </List.Item>
                ))}
              </List>
            )}
          </Form.Item>
          <Form.Item label="Family Status">
            <Picker
              columns={[familyStatusOptions]}
              value={[profile.familyStatus]}
              onConfirm={(value) => setProfile({ ...profile, familyStatus: value[0] })}
            >
              {(items, { open }) => (
                <Input
                  placeholder="Select your family status"
                  value={familyStatusOptions.find(option => option.value === profile.familyStatus)?.label || ''}
                  onClick={open}
                />
              )}
            </Picker>
          </Form.Item>
          <Form.Item label="Number of Kids">
            <Input
              type="number"
              placeholder="Enter number of kids"
              value={profile.numberOfKids || ''}
              onChange={handleNumberOfKidsChange}  // Pass only the value, not the full event
            />
          </Form.Item>
          <Form.Item label="Employment Status">
            <Picker
              columns={[employmentStatusOptions]}
              value={[profile.employmentStatus]}
              onConfirm={(value) => setProfile({ ...profile, employmentStatus: value[0] })}
            >
              {(items, { open }) => (
                <Input
                  placeholder="Select your employment status"
                  value={employmentStatusOptions.find(option => option.value === profile.employmentStatus)?.label || ''}
                  onClick={open}
                />
              )}
            </Picker>
          </Form.Item>

          <Form.Item label="Visa Status">
            <Picker
              columns={[visaStatusOptions]}
              value={[profile.visaStatus]}
              onConfirm={(value) => setProfile({ ...profile, visaStatus: value[0] })}
              disabled={profile.isStudent}
            >
              {(items, { open }) => (
                <Input
                  placeholder="Select your visa status"
                  value={visaStatusOptions.find(option => option.value === profile.visaStatus)?.label || ''}
                  onClick={open}
                />
              )}
            </Picker>
          </Form.Item>
          <Form.Item>
            <Checkbox
              checked={profile.isStudent}
              onChange={(checked) => handleStudentChange(checked)}
            >
              I am a student
            </Checkbox>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ProfilePage;
