// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles.css';  // Ensure you have the correct path

const Footer = () => {
  return (
    <div className="footer">
      <p className="footer-text">
        App version 1.0.2 | 
        <Link to="https://www.workhub.ai"> WorkHub Platform Inc. </Link> |  
        <Link to="/terms"> Terms & Conditions and Privacy Policy</Link>
      </p>
    </div>
  );
};

export default Footer;
